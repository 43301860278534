<template>
  <div class="row py-2 row_detail" :class="{ nostock: !item.available }">
    <div data-descr="галочка" class="col col-2 order-1 col-sm-1 order-sm-1">
      <template v-if="item.available">
        <label class="checkbox_container" :for="'row' + item.content_id">
          <input
            type="checkbox"
            name="detail[]"
            :checked="isSelected"
            :id="'row' + item.content_id"
            v-on:change="$root.$emit('toggle-order-item', parseInt(item.content_id))"
            :value="item.content_id"
            :disabled="!item.available" />
          <span class="checkmark"></span>
        </label>
      </template>
    </div>

    <div data-descr="описание" class="col col-7 order-5 col-sm-7 order-sm-3 col-md-4 col-xl-5 mb-2">
      <template v-if="item.status.show"
        ><nobr :class="item.status.color">{{ item.status.str }}</nobr
        ><br
      /></template>
      <a :href="item.url">{{ item.subgroup_str }} для {{ item.vendor_str }} {{ item.model_str }}</a>
      <div class="row descr">
        <div class="col-3">Артикул</div>
        <div class="col-9">{{ item.str_code }}</div>
        <template v-if="item.oem.length"
          ><div class="col-3">OEM</div>
          <div class="col-9">{{ item.oem.join(', ') }}</div></template
        >
        <div class="col-12">
          {{ item.type_str }} деталь<template v-if="item.type == 2">, доступно {{ item.count }} шт.</template>
        </div>
        <div class="col-12 pt-2 text-muted">добавлено в корзину {{ item.added }}</div>
      </div>
    </div>

    <div data-descr="фотки" class="col col-4 order-4 col-sm-3 offset-sm-0 order-sm-2 col-md-3 col-lg-3 col-xl-2">
      <template v-if="item.images.arr.length">
        <template v-for="(img, index) in item.images.arr">
          <a
            v-if="index == 0"
            :key="index"
            class="fancybox-thumb w-100 d-inline-block img-hover position-relative"
            :data-fancybox="'fancybox-thumb[' + img.code + ']'"
            :data-thumb="img.thumb"
            :title="'#' + (index + 1) + ', ' + img.title"
            :data-caption="'#' + (index + 1) + ', ' + img.title"
            :href="img.link">
            <div class="images">
              <template v-for="(el, i) in item.images.arr">
                <img v-if="i < 4" :key="i" loading="lazy" class="img-fluid w-100" :src="el.thumb" :alt="el.title" />
              </template>
            </div>
            <span v-for="(el, i) in item.icons" :key="i" :class="i"><img :src="el" /></span>
          </a>
          <a
            v-else
            :key="index"
            class="fancybox-thumb d-none"
            :data-fancybox="'fancybox-thumb[' + img.code + ']'"
            :data-thumb="img.thumb"
            :title="'#' + (index + 1) + ', ' + img.title"
            :data-caption="'#' + (index + 1) + ', ' + img.title"
            :href="img.link"></a>
        </template>
      </template>
      <img
        v-else
        loading="lazy"
        class="img-fluid w-100"
        :src="item.images.noimage.thumb"
        :alt="item.images.noimage.title" />
    </div>

    <div data-descr="цена" class="col col-8 order-2 col-sm-10 order-sm-4 col-md-3 col-lg-3 text-right prices">
      <div v-if="item.type == 2 && item.count > 1" class="input-group">
        <button
          :disabled="isChanging || amount == 1"
          type="button"
          class="btn btn-sm btn-outline-info px-3"
          v-on:click="$root.$emit('cart-item-reduce', { id: item.content_id })"
          title="Уменьшить количество в корзине">
          <font-awesome-icon icon="fas fa-minus" />
        </button>
        <button
          :disabled="isChanging"
          type="button"
          class="btn btn-sm btn-outline-info px-3"
          v-on:click="$root.$emit('cart-item-increase', { id: item.content_id })"
          title="Увеличить количество в корзине">
          <font-awesome-icon icon="fas fa-plus" />
        </button>
        <input
          :disabled="isChanging"
          type="text"
          name="amount"
          v-model="amount"
          min="0"
          step="1"
          class="form-control text-center"
          @keyup="handleDebouncedKeyup" />
        <span class="input-group-text">шт.</span>
      </div>
      <nobr>
        <span class="amount">{{ item.amount }}</span
        ><small class="text-muted"> шт.</small>
        <template v-if="item.price">
          x <span class="price">{{ item.price }}</span> ₽</template
        >
        <span v-else class="no" data-reason="no_price">цена не указана</span>
        <template v-if="item.total != 0">
          = <span class="item_total">{{ item.total }}</span> ₽</template
        >
      </nobr>
      <template v-if="item.price_normal != 0"
        ><br /><small class="text-muted">цена без скидки {{ item.price_normal }} ₽</small></template
      >
    </div>

    <div data-descr="кнопка" class="col col-2 order-3 col-sm-2 order-sm-5 col-md-1">
      <button
        style="font-size: 125%"
        v-on:click="$root.$emit('cart-item-remove', item.content_id)"
        type="button"
        class="btn btn-link"
        title="Удалить из корзины">
        <font-awesome-icon icon="far fa-trash-alt" />
      </button>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
library.add(faMinus, faPlus);
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
library.add(faTrashAlt);

export default {
  name: 'CartListDetail',
  components: {
    FontAwesomeIcon,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isChanging: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      amount: null,
    };
  },
  methods: {
    handleDebouncedKeyup: debounce(function () {
      this.saveInput();
    }, 750),
    saveInput: function () {
      if (this.isNumeric(this.amount)) {
        this.$root.$emit('cart-item-change', { id: this.item.content_id, value: this.amount });
      }
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
  },
  watch: {
    'item.amount': function (newVal) {
      this.amount = newVal;
    },
  },
  mounted() {
    this.amount = this.item.amount;
  },
};
</script>
