<template>
  <div>
    <catalog-row v-for="item in products" :key="item.strcode" :item="item" :cart="cart"></catalog-row>

    <div class="row">
      <div v-if="loadingmore" class="col-4 offset-4 py-4">
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 75%"></div>
        </div>
      </div>
      <div v-else class="col text-center py-3">
        <button-load-more v-if="onpage < total && page * onpage < total"
          >Показать ещё {{ total - (page + 1) * onpage > onpage ? onpage : total - (page + 1) * onpage }} из
          {{ total - (page + 1) * onpage }}</button-load-more
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

import ButtonLoadMore from './button-loadmore.vue';

import CatalogRow from './catalog-row.vue';

export default {
  name: 'CatalogLoadMore',
  data: function () {
    return {
      listtypes: ['bycategory', 'bydetails'],
      loadingmore: false,
      // список товаров для показа в "показать ещё"
      products: [],
      //
      page: 0,
    };
  },
  props: {
    cart: {
      type: Object,
      default() {
        return {};
      },
    },
    //
    listtype: {
      type: String,
      default: '',
    },
    vendor: {
      type: Number,
      default: 0,
    },
    model: {
      type: Number,
      default: 0,
    },
    group: {
      type: Number,
      default: 0,
    },
    subgroup: {
      type: Number,
      default: 0,
    },
    /*
    buttons: {
      type: Number,
      default: 0,
    },
    */
    // страницы и количество
    onpage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ButtonLoadMore,
    //
    CatalogRow,
  },
  computed: {
    //
  },
  methods: {},
  mounted() {
    this.$root.$on('load-more', () => {
      if (this.listtype != '' && this.listtypes.includes(this.listtype)) {
        let data = {
          listtype: this.listtype,
          //
          vendor: this.vendor,
          model: this.model,
          group: this.group,
          subgroup: this.subgroup,
          //
          // buttons: this.buttons,
          //
          page: this.page + 1,
          onpage: this.onpage,
        };

        this.loadingmore = true;

        axios
          .get(`${this.$store.getters.urlproductsmore}?` + new URLSearchParams(data).toString())
          .then((res) => {
            if (res.status == 200) {
              // ok
              this.products = this.products.concat(res.data.items);
              this.page++;
            } else {
              // not ok
              this.$bs5utils.Snack.show('warning', 'Ошибка в ответе сервера.', this.$store.getters.delay.warning);
            }
          })
          .catch((err) => {
            this.$bs5utils.Snack.show(
              'warning',
              'Не удалось загрузить список товаров, обновите страницу и попробуйте снова.',
              this.$store.getters.delay.warning
            );
            console.error(err);
          })
          .finally(() => {
            setTimeout(() => {
              this.loadingmore = false;
            }, 150);
          });
      } else {
        this.$bs5utils.Snack.show('danger', 'Непонятно что надо загрузить.', this.$store.getters.delay.error);
      }
    });
  },
};
</script>
